// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

@media print {
  .d-print-none {
      display: none !important; /* Hide elements with this class when printing */
  }
}


